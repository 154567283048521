"use client";
import Link from "next/link";
import { getLocalizedPath } from "../shared/formatURL";
// import { siteConfiguration } from "../shared/sites/sites";
import { useGlobalContext } from "../../app/context/store";
import { usePathname } from "next/navigation";
// import path from "path";

const CreateButton = ({ locale }: { locale: any }) => {
  const { user } = useGlobalContext();
  const pathname = usePathname();

  let buttonText = locale.create;
  // switch (siteConfiguration.category) {
  //   case "Porn" || "AsianPorn":
  //     buttonText = locale.create;
  //     break;
  //   case "Hentai":
  //     buttonText = locale.createh;
  //     break;
  //   case "Furry":
  //     buttonText = locale.createf;
  //     break;
  //   case "GayPorn":
  //     buttonText = locale.createg;
  //     break;
  //   case "ShemalePorn":
  //     buttonText = locale.creates;
  //     break;
  //   default:
  //     buttonText = locale.create; // Default case if none of the categories match
  // }

  return (
    <div className="flex flex-row">
      <Link
        prefetch={false}
        href={getLocalizedPath("create")}
        className="rounded-full bg-[#DD0772] px-4 py-2 text-sm font-bold text-white shadow-2xl hover:brightness-105"
      >
        {buttonText}
      </Link>
      {!pathname.includes("blog") && (
        <Link
          prefetch={false}
          href={getLocalizedPath("upgrade") + "?ref=create"}
          className={`${
            user.membership && user?.membership.level === 1
              ? "hidden"
              : "hidden sm:block"
          } ml-4 rounded-full bg-[#FFCC18] px-4 py-2 text-sm font-bold text-black shadow-2xl hover:brightness-105`}
        >
          {locale.upgrade}
        </Link>
      )}
    </div>
  );
};

export default CreateButton;
